import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import LinkCard from "../../components/LinkCard"

const IndexPage = () => {
    return (
        <Layout pageTitle="Awards - Brand Assets">
            <Row>
                <Col>
                    <Link to="/">Back</Link>
                </Col>
            </Row>
            <Row className="mt-5 justify-content-center">
                <LinkCard to="/awards/small-business-awards" title="Virtual Awards" bg="pink" />
                <LinkCard to="/awards/sme-awards" title="Awards" bg="pink" />
                <LinkCard to="/awards/logos-badges" title="Logos & Badges" />
                <LinkCard to="/awards/standard-graphics" title="Standard Graphics" />
                <LinkCard to="/awards/small-business-awards/templates" title="Templates" />

            </Row>
        </Layout>
    )
}

export default IndexPage
